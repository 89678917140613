define('mailsMailView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'moment',
  'horizontalCollapseView',
  'mailsMailBodyAndHeaderView',
  'mailsMailRightCollapsedView',
  'entities/mails'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DateUtils,
  moment,
  HorizontalCollapseView,
  BodyAndHeaderView,
  MailRightCollapsedView
) {
  'use strict';

  Module.exports = HorizontalCollapseView.extend({
    childView: BodyAndHeaderView,
    childRightView: MailRightCollapsedView,
    className: 'mail-view horizontal-collapse-view ' + HorizontalCollapseView.prototype.className,

    ui: _.extend({}, HorizontalCollapseView.prototype.ui, {
      content: '.mailBodyAndHeader-view .bodyAndHeader',
      loader: '.mailBodyAndHeader-view .loader-2'
    }),

    modelEvents: {
      'change:labels': 'renderLabels'
    },

    renderLabels: function() {
      this.fetch();
    },

    onShow: function () {
        this.fetch();
    },

    fetch: function () {
      var defer = $.Deferred();

      if (this.fetchRequest)
        this.fetchRequest.abort();

      //this.ui.loader.css('display', 'flex');
      //this.ui.content.hide();
      this.$el.addClass('open');

      var folderFullName='';
      var modelId='';
      if(this.model){
        modelId = this.model.get('id');
        if(this.model.get('folder')) {
          folderFullName = this.model.get('folder').fullName;
        }
      }

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('mails:get-mail-url', this.options.mailSecId, folderFullName, modelId),
        success: _.bind(function (item) {
          this.model = App.request('mails:model', item);

          this.childView.model = this.model;
          this.childRightView.model = this.model;

          defer.resolve();

          HorizontalCollapseView.prototype.onShow.apply(this, arguments);

          if(this.model.get('attachments') && this.model.get('attachments').length > 0) {
            HorizontalCollapseView.prototype.onRightOpen.apply(this, arguments);
          } else if(this.model.get('purchaseOrders') && this.model.get('purchaseOrders').length > 0) {
            HorizontalCollapseView.prototype.onRightClose.apply(this, arguments);
          }

        }, this)
      });

      return defer.promise();
    },

    open: function (model, mailSecId) {
      require([
        'windows',
        'mailsMailView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: model,
              mailSecId: mailSecId,
              parentView: this
            });
          }, this),
          id: 'Mail.' + model.get('id'),
          title: model.get('subject'),
          configName: 'Mail',
          width: 1200,
          height: 800,
          minWidth: 400,
          minHeight: 400
        });
      });
    }
  });
});

