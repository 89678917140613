define('emailView',[
  'app',
  'module',
  'backbone',
  'jquery',
  'marionette',
  'underscore',
  'settings',
  'trumbowyg',
  'buttonView',
  'selectView',
  'modalsAlertView',
  'modalsConfirmationView',
  'emailAttachmentsView',
  'mailsMailRightCollapsedPurchaseOrdersView',
  'template!emailView',
  'tokenField',
  'entities/caccounts/htmltemplates',
  'entities/mails'
], function(
  App,
  Module,
  Backbone,
  $,
  Marionette,
  _,
  Settings,
  Trumbowyg,
  ButtonView,
  SelectView,
  AlertView,
  ConfirmationView,
  EmailAttachmentsView,
  PurchaseOrdersView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'email-view',

    ui: {
      from: '.header .from .value',
      to: '.header .to > input',
      subject: '.subject > input',
      cc: '.cc > input',
      bcc: '.bcc > input',
      content: '.content textarea',
      attachments: '.attachments',
      loader: '.load'
    },

    regions: {
      fromUsersRegion: '.from-region',
      cancelRegion: '.cancel-region',
      sendRegion: '.send-region',
      attachmentsRegion: '.attachments-region',
      purchaseOrdersRegion: '.purchaseOrders-region',
      templatesRegion: '.templates-region'
    },

    initialize: function() {
      this.templates = [];
      this.attachments = [];
      this.sending = false;
      this.attachmensCollection = new Backbone.Collection(this.options.attachments);
      this.templateCollection = new Backbone.Collection();
      this.fromUsersCollection = new Backbone.Collection();
      this.linkedEmail = this.options.linkedEmail;
      if (!this.linkedEmail && this.options.ordersModels) {
        this.linkedEmail = new Backbone.Model({
          purchaseOrders: this.options.ordersModels.toJSON()
        });
      }
    },

    onDestroy: function() {
      if(this.fetchRequest)
        this.fetchRequest.abort();
    },

    onShow: function() {
      if(this.options.templateTypes && this.options.templateData && this.options.templateLocale) {
        var model = new Backbone.Model({
          id: '',
          value: _.i18n('mail-view.templates.none')
        });
        this.templateCollection.add(model);

        this.fetchTemplates().done(_.bind(function (templates) {
          var selectedId = '';
          _.each(templates, _.bind(function (template) {
            if(this.options.templateTypes.includes(template.templateType)) {
              var templateModel = App.request('htmltemplates:model', template);

              var model = new Backbone.Model({
                id: template.secId,
                value: template.templateType,
                templateModel: templateModel
              });
              this.templateCollection.add(model);

              if(this.options.defaultTemplate === template.templateType)
                selectedId = template.secId;
            }
          }, this));

          this.templatesView = new SelectView({
            collection: this.templateCollection,
            tabindex: 1
          });
          this.templatesRegion.show(this.templatesView);
          this.templatesView.on('change', _.bind(this.fetchTemplate, this));
          this.templatesView.setSelected(selectedId);
          this.fetchTemplate(this.templatesView.getSelected());
        }, this));
      }

      //----------------
      this.attachmentsView = new EmailAttachmentsView({
        collection: this.attachmensCollection
      });
      this.attachmentsRegion.show(this.attachmentsView);
      this.attachmentsView.on('change', _.bind(this.renderSendButton, this));

      //----------------
      if (!!Settings.configValue('advancedMode')) {
        if( this.linkedEmail) {
          this.purchaseOrdersView = new PurchaseOrdersView({
            model: this.linkedEmail,
            editMode: true
          });
          this.purchaseOrdersRegion.show(this.purchaseOrdersView);
        }
      }

      //----------------
      this.cancelView = new ButtonView({
        type: 'cancel',
        text: _.i18n('common.cancel'),
        title: _.i18n('common.cancel'),
        tabindex: 2
      });
      this.getRegion('cancelRegion').show(this.cancelView);
      this.cancelView.on('click', _.bind(this.onCancel, this));

      //----------------
      this.sendView = new ButtonView({
        type: 'send-mail',
        color: 'orange',
        text: _.i18n('common.send'),
        title: _.i18n('common.send'),
        enabled: false,
        tabindex: 3
      });
      this.sendRegion.show(this.sendView);
      this.sendView.on('click', _.bind(this.onSend, this));

      //----------------
      App.request('mails:getFromMails').done(_.bind(function (mails) {
        var defaultFromSecId = '';
        _.each(mails, function (fromMail) {
          this.fromUsersCollection.add({id: fromMail.secId, value: fromMail.email});
          if(this.options.from === fromMail.email)
            defaultFromSecId = fromMail.secId;
        }, this);

        this.fromUsersView = new SelectView({
          collection: this.fromUsersCollection,
          tabindex: 2,
          allowFromSelection: this.options.allowFromSelection === false ? false : true
        });
        this.fromUsersRegion.show(this.fromUsersView);
        if(defaultFromSecId)
          this.fromUsersView.setSelected(defaultFromSecId);

        this.renderSendButton();
      }, this));
    },

    onRender: function() {
      var content = '';
      if(this.options.content)
        content = this.options.content;

      this.renderBody(content);

      if(this.options.subject)
        this.renderSubject(this.options.subject);

      //----------------
      this.ui.to.tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      });

      if(this.options.to) {
        _.each(this.options.to.split(';'), function(email) {
          this.ui.to.tokenfield('createToken', { value: email, label: email });
        }, this);
      }

      //----------------
      this.ui.cc.tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      });

      if(this.options.cc) {
        _.each(this.options.cc.split(';'), function(email) {
          this.ui.cc.tokenfield('createToken', { value: email, label: email });
        }, this);
      }

      //----------------
      this.ui.bcc.tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      });

      if(this.options.bcc) {
        _.each(this.options.bcc.split(';'), function(email) {
          this.ui.bcc.tokenfield('createToken', { value: email, label: email });
        }, this);
      }

      //----------------
      this.$el.find('.tokenfield').removeClass('form-control');
    },

    renderSendButton: function() {
      if(this.fromUsersCollection.models.length > 0) {
        var activated = true;

        _.each(this.attachmensCollection.models, function (attachment) {
          if(attachment.get('error') || !attachment.get('loaded'))
            activated = false;
        }, this);

        if(activated)
          this.sendView.enabled(true);
        else
          this.sendView.enabled(false);
      } else {
        this.sendView.enabled(false);
      }
    },

    onCancel: function() {
      this.windowView.close();
    },

    onSend: function() {
      if(this.ui.subject.val() === '') {
        AlertView.prototype.open('', _.i18n('mail-view.no-subject-send-error'));
      } else
        this.sendMail();
    },

    sendMail: function() {
      this.sendView.enabled(false);

      var content = this.ui.content.trumbowyg('html').replaceAll('<p>', '').replaceAll('</p>', '<br>');

      var emails = this.ui.to.tokenfield('getTokens');
      var email = _.pluck(emails, 'value').join(';');
      var cc = _.pluck(this.ui.cc.tokenfield('getTokens'), 'value').join(';');
      var bcc = _.pluck(this.ui.bcc.tokenfield('getTokens'), 'value').join(';');

      var attachments = [];
      _.each(this.attachmensCollection.models, function (attachment) {
        attachments.push({
          base64: attachment.get('base64'),
          contentDisposition: attachment.get('content-disposition'),
          contentType: attachment.get('content-type'),
          name: attachment.get('name')
        });
      });

      // Get PurchaseOrderIds from the PurchaseOrdersView as user can unlink them freely
      var pois = [];
      if(this.purchaseOrdersView && this.purchaseOrdersView.collection &&
          this.purchaseOrdersView.collection.models.length > 0) {
          _.each(this.purchaseOrdersView.collection.models, function(po) {
            var poi = po.get('purchaseOrderId');
            if(poi) {
              var isRemoved = po.get('removed') === true;
              pois.push(isRemoved ? 'R' + poi : poi);
            }
          });
      } else if(this.options.templateData && this.options.templateData.pois) {
          pois = this.options.templateData.pois;
      }

      this.ui.loader.css('display', 'flex');

      //console.log('sendEmail pois', pois, this.purchaseOrdersView.collection);

      App.request('mails:send',
          this.fromUsersView.getSelected().get('id'),
          email,
          cc,
          bcc,
          this.ui.subject.val(),
          content,
          attachments,
          pois).done(_.bind(function () {
        if (this.options.onMailSend)
          this.options.onMailSend(email);
        this.ui.loader.hide();
        this.windowView.close();
      }, this)).fail(_.bind(function () {
        AlertView.prototype.open('', _.i18n('gmail.sendEmailFail'));
        this.ui.loader.hide();
        this.renderSendButton();
      }, this));
    },

    onDelete: function(view) {
      var index = this.attachments.indexOf(view.model);
      if(index > -1) {
        this.attachments.splice(index, 1);
      }
    },

    fetchTemplate: function(model) {
      var subject = null;
      var body = null;

      var template = model.get('templateModel');
      if(template) {
        try
        {
          subject = template.buildSubject(this.options.templateData, null, this.options.templateLocale);
        }
        catch (e)
        {
          AlertView.prototype.open('', _.i18n('mail-view.templates.subjectError').replace('%s', e.message));
          console.log(e);
        }

        try
        {
          body = template.build(this.options.templateData, null, this.options.templateLocale);
        }
        catch (e)
        {
          AlertView.prototype.open('', _.i18n('mail-view.templates.bodyError').replace('%s', e.message));
          console.log(e);
        }
      }

      if (!this.options.content) {
        this.ui.subject.val('');
      }

      if(subject && !this.options.content) {
        this.renderSubject(subject);
      }

      if(body) {
        if (this.options.content) {
          this.renderBody(body + this.options.content);
        } else {
          this.renderBody(body);
        }

      }
      else
        this.renderBody('');
    },

    renderSubject: function(subject){
      this.ui.subject.val(subject);
    },

    renderBody: function(content) {
      content = this.replacePlaceholders(content);

      this.trumbowyg = this.ui.content.trumbowyg({
        removeformatPasted: true,
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
          ['fontsize']
        ],
        plugins: {
          allowTagsFromPaste: true
        }
      });

      if(this.options.previousEmails){
        content += this.options.previousEmails;
      }

      this.ui.content.trumbowyg('html', content);
    },

    fetchTemplates: function() {
      var defer = $.Deferred();

      if(this.fetchRequest)
        this.fetchRequest.abort();

      var params = {
        pageSize: 100,
        first: 0,
        _search: false,
        nd: new Date().getTime(),
        rows: 100,
        page: 1,
        sidx: '',
        sord: 'asc'
      };

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: Settings.url('compuzz2', 'v2/htmltemplates/advancedSearch', params),
        success: _.bind(function (templates) {
          this.templates = templates;
          defer.resolve(templates);
        }, this)
      });

      return defer.promise();
    },

    replacePlaceholders: function(content) {
      var regex = /\{template:([^\}]+)\}/g;
      var matches;

      while ((matches = regex.exec(content)) !== null) {
        try {
          for(var i = 0; i < this.templates.length; i++) {
            if(this.templates[i].templateType === matches[1]) {
              var model = App.request('htmltemplates:model', this.templates[i]);
              var templateContent = model.build(this.options.templateData, null, this.options.templateLocale);
              content = content.replace('{template:' + matches[1] + '}', templateContent);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      if(this.options.templateData && this.options.templateData.supplier) {
        regex = /\{supplier:([^\}]+)\}/g;

        while ((matches = regex.exec(content)) !== null) {
          try {
            var attr = null;
            if (matches && matches.length > 1)
              attr = matches[1];

            var value = null;
            if (attr) {
              if (this.options.templateData.supplier[attr])
                value = this.options.templateData.supplier[attr];
            }

            if(value)
                content = content.replace('{supplier:' + attr + '}', value);
          } catch (e) {
            console.log(e);
          }
        }
      }

      return content;
    },

    //Static methods
    open: function(data) {
      var title = _.i18n('email.sendEmail');
      if(data && data.title)
        title = data.title;

      var id = 'email.';
      if(data && data.id)
        id += data.id;

      require([
        'windows',
        'emailView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          id: id,
          title: title,
          width: '80%',
          height: '80%',
          minWidth: 600,
          minHeight: 400
        });
      });
    }
  });
});

