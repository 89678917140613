define('mailsMenuView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'buttonView',
  'selectView',
  'settings',
  'emailView',
  'template!mailsMenuView',
  'notificationCenter',
  'jstree'
], function(
  App,
  Module,
  Backbone,
  Marionette,
  _,
  ButtonView,
  SelectView,
  Settings,
  EmailView,
  Tpl,
  NotificationCenter
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'menu-view',

    ui: {
      folders: '.folders'
    },

    regions: {
      newMailRegion: '.new-mail-region',
      mailsRegion: '.mails-region'
    },

    initialize: function() {
        this.refreshTimeout = null;
        this.mailSecId = this.options.from || undefined;
        this.folder = this.options.folder || 'INBOX';
        this.emitterId = undefined;

        this.setupNotificationListeners();
    },

    // Helper method to validate notification response
    validateNotificationResponse: function(response) {
        return response &&
            response.content &&
            response.content.content &&
            response.content.content.data &&
            response.content.emitterId === this.emitterId &&
            typeof response.content.content.text === 'string' &&
            response.content.content.text.startsWith(this.mailSecId);
    },

    // Helper method to update emitterId
    updateEmitterId: function(response) {
        if (response.content && !isNaN(response.content.emitterId)) {
            var newEmitterId = parseInt(response.content.emitterId, 10);
            if (this.emitterId === undefined || newEmitterId > this.emitterId) {
                this.emitterId = newEmitterId;
            }
        }
    },

    // Helper method to handle message notifications
    handleMessageNotification: function(response) {
        this.updateEmitterId(response);

        if (!this.validateNotificationResponse(response)) return;

        var that = this;
        response.content.content.data.forEach(function(label) {
            if(label.id === that.folder || that.folder === 'ALL MAILS') {
                that.refreshFolder();
            }
        });
    },

    // Helper method to handle label notifications
    handleLabelNotification: function(response) {
        this.updateEmitterId(response);

        if (!this.validateNotificationResponse(response)) return;

        this.refreshFolders(response.content.content.data);

        // if response.content.content.text contains this.folder, refreshFolder()
        if(this.folder === 'ALL MAILS' || response.content.content.text.indexOf(this.folder) !== -1) {
          this.refreshFolder();
        }
    },

    // Setup notification listeners
    setupNotificationListeners: function() {
        var nc = NotificationCenter.getInstance();
        var events = NotificationCenter.events;

        // Message events
        nc.on(events.EMAILS_GMAIL_API_MESSAGE_ADDED,
            this.handleMessageNotification, this);

        nc.on(events.EMAILS_GMAIL_API_MESSAGE_DELETED,
            this.handleMessageNotification, this);

        // Label events
        nc.on(events.EMAILS_GMAIL_API_MESSAGE_LABELS_ADDED,
            this.handleLabelNotification, this);

        nc.on(events.EMAILS_GMAIL_API_MESSAGE_LABELS_REMOVED,
            this.handleLabelNotification, this);
    },

    onDestroy: function() {
      this.removeNotificationListeners();
    },

    // Setup notification listeners
    removeNotificationListeners: function() {
        var nc = NotificationCenter.getInstance();
        var events = NotificationCenter.events;

        // Message events
        nc.off(events.EMAILS_GMAIL_API_MESSAGE_ADDED,
            this.handleMessageNotification, this);

        nc.off(events.EMAILS_GMAIL_API_MESSAGE_DELETED,
            this.handleMessageNotification, this);

        // Label events
        nc.off(events.EMAILS_GMAIL_API_MESSAGE_LABELS_ADDED,
            this.handleLabelNotification, this);

        nc.off(events.EMAILS_GMAIL_API_MESSAGE_LABELS_REMOVED,
            this.handleLabelNotification, this);
    },

    refreshFolders: function(data) {

      clearTimeout(this.refreshTimeout);
      var that = this;
      function rt() {
        var tree = that.ui.folders.jstree(true);
        tree.settings.core.data = data.map(that.processItem.bind(that));
        tree.refresh();
      }
      this.refreshTimeout = setTimeout(rt, 5000);
    },

    processItem: function(item) {
      var itemText = item.text;
      if (item.unreadMessages) {
        itemText = '<span class="unread-messages">' + itemText + '</span>';
      }

      var folder = {
        id: item.id,
        text: itemText,
        messageCount: item.messageCount,
        unreadMessages: item.unreadMessages
      };

      if (item.children && item.children.length > 0) {
        folder.children = item.children.map(this.processItem.bind(this));
      } else {
        folder.children = [];
      }

      return folder;
    },

    onRender: function() {
      this.newButton = new ButtonView({
        type: 'add',
        color: 'orange',
        text: _.i18n('mails.new'),
        title: _.i18n('mails.new')
      });

      this.getRegion('newMailRegion').show(this.newButton);
      this.newButton.on('click', this.onNewMail, this);

      //--------------------
      this.mailsCollection = new Backbone.Collection();
      this.mailsView = new SelectView({
        collection: this.mailsCollection
      });
      this.getRegion('mailsRegion').show(this.mailsView);
      this.mailsView.on('change', _.bind(this.onMailChange, this));

      App.request('mails:getFromMails').done(_.bind(function(addresses) {
        _.each(addresses, function (address) {
          this.mailsCollection.add({id: address.secId, value: address.email});
        }, this);

        if(addresses.length > 0) {
          if(!this.mailSecId)
            this.mailSecId = addresses[0].secId;
          this.options.listView.mailsView.setMailSecId(this.mailSecId);
          this.mailsView.setSelected(this.mailSecId);
          this.ui.folders.jstree('refresh');
        }

      }, this));

      //--------------------
      this.ui.folders.jstree({
        'core': {
          'data': _.bind(this.fetch, this),
          'check_callback': true,
          'multiple' : false,
          'themes': {
            'responsive': true,
            'variant': 'small',
            'stripes': false
          },
          'animation': 0
        },
        'plugins': ['dnd']
      }).on('open_node.jstree', _.bind(function(e, data) {
        if(data.node.state.fetch)
          return;

        /*this.fetch(data.node, _.bind(function(folders) {
          data.node.state.fetch = true;

          this.ui.folders.jstree('delete_node', data.node.id + '/load');

          _.each(folders, _.bind(function(folder) {
            this.ui.folders.jstree('create_node', data.node, folder, 'last');
          }, this));

          this.ui.folders.jstree('open_node', data.node);
        }, this));*/
        this.ui.folders.jstree('open_node', data.node);

      }, this)).on('select_node.jstree', _.bind(function(e, data) {
        // Prevent a simple refresh to clear search
        if(this.folder !== data.node.id) {
          this.folder = data.node.id;
          this.trigger('folder:selected', data.node.id, data.node.original.messageCount);
        }
        App.navigate('mails/' + this.mailSecId + '/' + encodeURIComponent(data.node.id), {trigger: false});
      }, this)).on('loaded.jstree', _.bind(function() {
        this.ui.folders.jstree('select_node', this.folder);
      }, this)).on('refresh.jstree', _.bind(function() {
        this.ui.folders.jstree('select_node', this.folder);
      }, this));
    },

    refreshFolder: function() {
      clearTimeout(this.refreshTimeoutFolder);
      var that = this;
      function rtf() {
        that.trigger('folder:selected', that.folder, undefined);
        App.navigate('mails/' + that.mailSecId + '/' + encodeURIComponent(that.folder), {trigger: false});
      }
      this.refreshTimeoutFolder = setTimeout(rtf, 5000);
    },

    fetch: function (node, cb) {
      if(!this.mailSecId)
        cb([]);

      var params = {};
      params.folder = null;

      if(node.id !== '#')
        params.folder = node.id;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url:  App.request('mails:get-folders-url', this.mailSecId, params),
        success: _.bind(function (items) {
          if(items) {
            var folders = items.map(this.processItem.bind(this));
            cb(folders);
          }
        }, this)
      });
    },

    onMailChange: function() {
      clearTimeout(this.refreshTimeout);
      clearTimeout(this.refreshTimeoutFolder);

      this.folder = 'INBOX';
      this.mailSecId = this.mailsView.getSelected().get('id');
      this.options.listView.mailsView.folder = this.folder;
      this.options.listView.mailsView.footerView.setCurrentPage(1);
      this.options.listView.mailsView.setMailSecId(this.mailSecId);
      this.ui.folders.jstree('refresh');
      App.navigate('mails/' + this.mailSecId, {trigger: false});
    },

    onNewMail: function() {
      if(!this.mailSecId)
        return;
      var from = null;
      _.each(this.mailsCollection.models, function(model) {
        if(model.get('id') === this.mailSecId)
          from = model.get('value');
      }, this);

      if(!from)
        return;

      EmailView.prototype.open({
        from: from,
        allowFromSelection: true,
        title: '',
        id: 'new-mail',
        templateTypes: ['MAIL_SIGN'],
        defaultTemplate: 'MAIL_SIGN',
        templateData: {
          user: Settings.get('currentUser'),
          customer: { }
        },
        templateLocale: Settings.get('lang')
      });
    }
  });
});

