define('mailsMailHeaderView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'moment',
  'mailsMailHeaderLabelsView',
  'emailView',
  'template!mailsMailHeaderView',
  'entities/mails'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DateUtils,
  moment,
  LabelsView,
  EmailView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'mail-header-view',

    ui: {
      subject: '.subject',
      fromName: '.from .name',
      fromMail: '.from .mail',
      toName: '.to .name',
      toMail: '.to .mail',
      ccName: '.cc .name',
      ccMail: '.cc .mail',
      date: '.date',
      loader: '.loader-ctn',
      replyButton: '.reply',
      customerSecId: '.customerSecId'
    },

    events: {
      'click @ui.replyButton': 'onReply'
    },

    regions: {
      labelsRegion: '.labels-region'
    },

    onShow: function () {

      this.renderContent();

      this.labelsView = new LabelsView({
        model: this.model,
        mailSecId: this.options.mailSecId
      });
      this.getRegion('labelsRegion').show(this.labelsView);

    },

    renderContent: function () {

      if (this.model.get('from') && this.model.get('from').length > 0) {
        if (this.model.get('from')[0].personal) {
          this.ui.fromName.html(this.model.get('from')[0].personal);
          this.ui.fromName.css('display', 'flex');
        } else {
          this.ui.fromName.hide();
        }
        this.ui.fromMail.html('&lt;' + this.model.get('from')[0].address + '&gt;');
      }
      if (this.model.get('to') && this.model.get('to').length > 0) {
        if (this.model.get('to')[0].personal) {
          this.ui.toName.html(this.model.get('to')[0].personal);
          this.ui.toName.css('display', 'flex');
        } else {
          this.ui.toName.hide();
        }
        this.ui.toMail.html('&lt;' + this.model.get('to')[0].address + '&gt;');
      }
      if (this.model.get('cc') && this.model.get('cc').length > 0) {
        if (this.model.get('cc')[0].personal) {
          this.ui.ccName.html(this.model.get('cc')[0].personal);
          this.ui.ccName.css('display', 'flex');
        } else {
          this.ui.ccName.hide();
        }
        this.ui.ccMail.html('&lt;' + this.model.get('cc')[0].address + '&gt;');
      }

      this.ui.subject.html(this.model.get('subject'));

      this.ui.date.html(DateUtils.toMDYHHMMFormat(this.model.get('sentDate')));

      var customerSecId = this.model.get('customerSecId');
      if (customerSecId) {
        this.ui.customerSecId.css('display', 'flex');
        this.ui.customerSecId.attr('href', '#/customers/' + this.model.get('customerSecId') + '/profile');
      }
    },

    onReply: function () {
      var from = null;
      if (this.options.parentView && this.options.parentView.options.parentView) {
        _.each(this.options.parentView.options.parentView.options.parentView.options.parentView.menuView.mailsCollection.models,
            function (model) {
              if (model.get('id') === this.options.mailSecId)
                from = model.get('value');
            }, this);
      } else {
        from = this.model.get('emailAddress').email;
      }

      if (!from)
        return;

      var to = null;
      var cc = null;

      if (this.model.get('from') && this.model.get('from').length > 0)
        to = this.model.get('from')[0].address;
      if (this.model.get('cc') && this.model.get('cc').length > 0)
        cc = this.model.get('cc')[0].address;

      if (!to)
        return;

      var pois = [];
      if(this.model.get('purchaseOrders')) {
        _.each(this.model.get('purchaseOrders'), function (po) {
          pois.push(po.purchaseOrderId);
        });
      }

      var content = '<br><br><br>';
      content += DateUtils.toMDYHHMMFormat(this.model.get('sentDate'));
      //content += moment(this.model.get('sentDate')).format('ddd, MMM D, YYYY [at] h:mmA');
      content += ' &lt;' + to + '&gt; wrote:<br><br>';
      content += this.model.get('content');

      EmailView.prototype.open({
        from: from,
        to: to,
        cc: cc,
        content: content,
        subject: 'Re: ' + this.model.get('subject'),
        allowFromSelection: true,
        title: '',
        id: 'new-mail',
        templateTypes: ['MAIL_SIGN'],
        defaultTemplate: 'MAIL_SIGN',
        templateData: {
          user: Settings.get('currentUser'),
          customer: { },
          pois: pois
        },
        templateLocale: Settings.get('lang'),
        linkedEmail: this.model
      });
    }

  });
});

