
define('template!mailsMailRightCollapsedPurchaseOrderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="purchaseOrderCard' +
((__t = ( removed )) == null ? '' : __t) +
'" data-poi="' +
((__t = ( poi )) == null ? '' : __t) +
'">\n  ';
 if(editMode) { ;
__p += '\n  <div class="purchaseOrderCard-buttons">\n    <div class="unlink-button">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>\n    </div>\n  </div>\n  ';
 } ;
__p += '\n\n  <div class="purchaseOrder_title">\n    ';
 if (imgUrl) { ;
__p += '\n        <img src="' +
((__t = ( imgUrl )) == null ? '' : __t) +
'" alt="product image" />\n    ';
 } ;
__p += '\n    <div class="purchaseOrder_desc">\n      ' +
((__t = ( quantity )) == null ? '' : __t) +
'<br/>\n      ' +
((__t = ( price )) == null ? '' : __t) +
'\n    </div>\n  </div>\n  ' +
((__t = ( statusLabel )) == null ? '' : __t) +
'\n\n</div>';

}
return __p
};});

