define('mailsMailRightCollapsedPurchaseOrdersView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'mailsMailRightCollapsedPurchaseOrderView',
  'template!mailsMailRightCollapsedPurchaseOrdersView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  MailPurchaseOrderView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'purchaseOrders-view',
    childView: MailPurchaseOrderView,
    childViewContainer: '.purchaseOrders-container',

    modelEvents: {
      'change': 'fetch'
    },

    initialize: function() {
        this.collection = new Backbone.Collection();
        this.fetch();
        this.readTimer = null;
    },

    fetch: function() {
      this.collection.reset();
      var promises = [];
      promises.push(App.request('orders:statuses', 'offer'));
      promises.push(App.request('orders:statuses', 'command'));

      $.when.apply($, promises).then(_.bind(function(offerStatusResult,  orderStatusResult) {
        if(this.model.get('purchaseOrders')) {
          _.each(this.model.get('purchaseOrders'), function (po) {
            po.offerStatuses = offerStatusResult.models;
            po.orderStatuses = orderStatusResult.models;
            po.editMode = this.options.editMode;
            this.collection.add(new Backbone.Model(po));
          }, this);
        }
      }, this));
    }
  });
});
